* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	scroll-behavior: smooth;
}
@font-face {
	font-family: 'DrukWideBold';
	src: url('Files/Font/DrukWideBold.ttf');
	src: url('Files/Font/DrukWideMedium.ttf');
}
@font-face {
	font-family: 'DrukWideMedium';
	src: url('Files/Font/DrukWideMedium.ttf');
}
:root {
	--font-druk-wide-bold: 'DrukWideBold', sans-serif;
	--font-druk-wide-medium: 'DrukWideMedium', sans-serif;
	--font-montserrat: 'Montserrat-Regular', sans-serif;
	--color-font-grey: #5c5c5c;
	--color-dark: #0a0a0a;
	--bg-color: #131313;
	--cubicbZ: cubic-bezier(0.9, 0, 0.1, 1);
	--gap: 170px;
	--fz-big: 60px;
}

body {
	font-family: var(--font-druk-wide-bold);
	background-color: var(--color-dark);
	color: #fff;
	cursor: crosshair;
}
body::before {
	background-image: url(./Files/Img/noise.png);
	content: '';
	height: 300%;
	left: -50%;
	top: -100%;
	position: fixed;
	z-index: 999;
	pointer-events: none;
	width: 300%;
	opacity: 0.3;
	animation: grain 8s steps(10) infinite;
}
@keyframes grain {
	0%,
	100% {
		transform: translateX(0);
	}
	10% {
		transform: translateX(-5%);
	}
	20% {
		transform: translateX(-15%);
	}
	30% {
		transform: translateX(7%);
	}
	40% {
		transform: translateX(-5%);
	}
	50% {
		transform: translateX(-15%);
	}
	60% {
		transform: translateX(15%);
	}
	70% {
		transform: translateX(0);
	}
	80% {
		transform: translateX(3%);
	}
	90% {
		transform: translateX(-10%);
	}
}
@keyframes grain {
	0%,
	100% {
		transform: translate(0, 0);
	}
	10% {
		transform: translate(-5%, -10%);
	}
	30% {
		transform: translate(3%, -15%);
	}
	50% {
		transform: translate(12%, 9%);
	}
	70% {
		transform: translate(9%, 4%);
	}
	90% {
		transform: translate(-1%, 7%);
	}
}
.wrapp {
	height: auto;
	position: relative;
	z-index: 1;
}

/* Header */
.home {
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.home_header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 60px;
}
.home_header_logo {
	font-size: 1.5vw;
}
.home_header_logo a {
	text-decoration: none;
	color: #fff;
}
/* HOME MAIN */
.home_main {
	position: relative;
}

.home_title {
	font-size: 6vw;
	display: flex;
	flex-wrap: wrap;
	max-width: 1800px;
	padding: 0 120px;
	line-height: 0.9;
	letter-spacing: 10px;
}
.home_main_img img {
	height: 52vh;
	width: auto;
	position: absolute;
	right: 10vw;
	top: -10px;
	opacity: 0.3;
	animation: rotate 50s infinite linear;
	z-index: -1;
}
@keyframes rotate {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.lastname .title_letter_blink,
.job .title_letter_blink {
	-webkit-text-stroke: 1px #fff;
	color: transparent;
	border: 1px #fff;
	font-weight: 400;
}
.title_letter_blink {
	opacity: 1;
	transition: 0.3s;
}
.title_letter_blink:hover {
	opacity: 0.3;
}

/* HOME FOOTER */
.home_footer {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 60px;
	font-size: 16px;
}

.home_arrow {
	position: absolute;
	bottom: 60px;
	left: 50%;
}

.home_footer_content ul {
	list-style: none;
	display: flex;
	align-items: center;
	gap: 20px;
}

.home_footer a {
	text-decoration: none;
	color: #fff;
}
.home_arrow img {
	height: 55px;
	width: auto;
}
.home_arrow {
	animation: bounce 3s infinite linear;
	cursor: pointer;
}

@keyframes bounce {
	0% {
		transform: scale(1);
		opacity: 0.3;
	}
	50% {
		transform: scale(1.1);
		opacity: 1;
	}
	100% {
		transform: scale(1);
		opacity: 0.3;
	}
}

/* 
ABOUT
*/

.section {
	position: relative;
	width: 100%;
	height: 100vh;
}
.about_title {
	display: flex;
	justify-content: flex-end;
	margin: 50px 0 50px 50px;
	font-size: 5vw;
	border-bottom: 1px solid #5c5c5c;
}

.reveal {
	padding: 50px;
}
.reveal_img {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	row-gap: 150px;
	width: 100%;
	height: auto;
	padding-bottom: 170px;
}
.reveal_img-item {
	position: relative;
	width: calc(50% - var(--gap) / 2);
	height: 550px;
	padding-left: 53px;
	overflow: hidden;
}
.reveal_img-item:nth-child(even) {
	transform: translateY(150px);
}
.reveal_img-num {
	position: absolute;

	top: 0;
	left: 0;
	font-size: 40px;
	color: #fff;
	writing-mode: vertical-lr;
	transform: scale(-1, -1);
}
.reveal_img-inner,
.reveal_img-bl {
	width: 100%;
	height: 100%;
	clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
}
.reveal_img-inner {
	background: var(--bg-color);
}
.reveal_img-bl {
	overflow: hidden;
}
.reveal_img-img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	will-change: transform;
}

.accordion {
	padding: 50px 100px;
}

.accordion_title {
	position: relative;
}
.accordion_title p {
	font-size: 15px;
	color: #fff;
}
.accordion_title h4 {
	font-size: 2.8vw;
	margin-left: 100px;
	text-transform: uppercase;
	-webkit-text-stroke: 1px #fff;
	color: transparent;
	font-weight: 400;
}

.icon {
	position: absolute;

	color: #fff;
	font-size: 25px;
	cursor: pointer;
	right: 7vw;
	top: 0;
}
.icon img {
	height: 50px;
	width: auto;
}

.accordion_content {
	margin-left: 100px;
	max-width: 1400px;
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	opacity: 1;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
	opacity: 0;
	animation-name: accordionAnimate;
	animation-duration: 0.5s;
}

.accordion_content p {
	max-width: 900px;
	font-size: 1.3vw;
	color: var(--color-font-grey);
}
.accordion.open .accordion_content {
	max-height: 1000px; /* Ajustez la valeur en fonction de votre contenu */
	opacity: 1;
}

@keyframes accordionAnimate {
	from {
		/* Définissez les styles de départ de l'animation ici */
		max-height: 0;
		opacity: 0;
	}
	to {
		/* Définissez les styles finaux de l'animation ici */
		max-height: 1000px; /* Ajustez la valeur en fonction de votre contenu */
		opacity: 1;
	}
}
.contact {
	height: 100vh;
}
.contact_main {
	height: 70vh;
	width: 70vw;
	margin: auto;
}
.container {
	display: flex;
	flex-direction: column;
}
.form-group {
	position: relative;
	padding: 50px 0;
}

.form-control {
	border: none;
	border-bottom: 1px solid #5c5c5c;
	width: 100%;
	padding: 10px;
	background: none;
	transition: 1s ease-in-out;
	color: #fff;
	font-size: 2vw;
}
.form-group:nth-child(3) {
	margin-top: 100px;
}

.form-control:focus,
.form-control:valid {
	border: none;
	border-bottom: 1px solid blue;
	outline: none;
	margin: 1px 0;
}

.form-group > label {
	position: absolute;
	top: 20px;
	left: 10px;
	color: #fff;
	font-size: 2vw;
	cursor: text;
	transition: 0.2s;
}

.form-control:focus + label,
.form-control:valid + label {
	top: -10px;
	font-size: 1.5vw;
	cursor: default;
}

.form-control:focus + label {
	color: blue;
}

.container button {
	color: #fff;
	background: none;
	border: 1px solid #5c5c5c;
	padding: 10px 40px;
	width: fit-content;
	margin: auto;
	outline: none;
	font-family: inherit;
	transition: 0.5s;
}
.container button:focus {
	color: #5c5c5c;

	box-shadow: 1px 1px 5px blue;
}
.footer_top_wrapper {
	display: flex;
	align-items: center;
	gap: 50px;
}
.footer_top_wrapper h3 {
	font-size: 2vw;
}
.footer_top_wrapper ul {
	list-style: none;
	display: flex;
	align-items: center;
	gap: 50px;
}
.footer_top_wrapper ul a {
	text-decoration: none;
	color: #5c5c5c;
	font-size: 1vw;
}
.footer {
	padding: 30px 100px;
	border-top: 1px solid #5c5c5c;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.footer_bottom_wrapper {
	font-size: 1vw;
	color: #5c5c5c;
}

/* 
			RESPONSIVE
*/

@media screen and (min-width: 600px) and (max-width: 1250px) {
	.home_main_img img {
		height: 40vh;
		width: auto;
		position: absolute;
		right: 9%;
		top: -10px;
		opacity: 0.3;
		animation: rotate 50s infinite linear;
		z-index: -1;
	}
}

@media screen and (max-width: 559px) {
	.home_title {
		padding: 0;
		line-height: 1.2;
		letter-spacing: 10px;
		justify-content: center;
		text-align: center;
	}
	.home_main_img img {
		height: 40vh;
		width: auto;
		position: absolute;
		left: 10%;
		top: 10%;
		opacity: 0.3;
		animation: rotate 50s infinite linear;
		z-index: -1;
	}
	.home_footer {
		padding: 0 20px;
		margin: 50px 0;
	}
	.home_arrow {
		position: absolute;
		bottom: 0;
		left: 57%;
	}
	.reveal {
		padding: 20px;
	}
	.reveal_img {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex-wrap: wrap;
		row-gap: 100px;
		width: 100%;
		height: auto;
	}
	.reveal_img-item {
		position: relative;
		width: calc(100%);
		height: 250px;
		padding-left: 53px;
		overflow: hidden;
	}
	.reveal_img-item:nth-child(even) {
		transform: translateY(0px);
	}
	.accordion {
		padding: 50px 20px;
	}
	.accordion_title h4 {
		font-size: 20px;
		margin-left: 100px;
		text-transform: uppercase;
		-webkit-text-stroke: 1px #fff;
		color: transparent;
		font-weight: 400;
	}
	.accordion_content {
		margin-left: 0;
	}
	.accordion_content p {
		max-width: 900px;
		font-size: 18px;
		color: var(--color-font-grey);
	}
	.icon {
		position: absolute;
		color: #fff;
		font-size: 25px;
		cursor: pointer;
		right: 0;
		top: -15px;
	}
	.icon img {
		height: 30px;
		width: auto;
	}

	.footer {
		padding: 5px;
		border-top: 1px solid #5c5c5c;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.footer_top_wrapper h3 {
		font-size: 12px;
		display: none;
	}
	.footer_top_wrapper ul {
		list-style: none;
		display: flex;
		align-items: center;
		gap: 5px;
	}
	.footer_top_wrapper {
		display: flex;
		align-items: center;
		gap: 50px;
	}

	.footer_top_wrapper ul a {
		text-decoration: none;
		color: #5c5c5c;
		font-size: 11px;
	}

	.footer_bottom_wrapper {
		font-size: 7px;
		color: #5c5c5c;
	}
}
